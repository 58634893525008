import React, {useState, forwardRef} from "react";
import {Button} from "../../../../@/components/ui/button";
import {SlArrowUp, SlArrowDown} from "react-icons/sl";
import defaultImage from "../../../../Images/defaultImage.png";
import {useLazyGetUploadUrlQuery, useUploadFileMutation, usePrintImageMutation, useLazyGetDetailQuery} from "../../../../store";
import DownloadPropertyButton from "./DownloadPropertyButton";

const cateArray = ["치과", "미용", "감기", "통증", "한의원"];

const AreaType = {
    STATION: {name: "역세권", key: "station"},
    DISTRIBUTION: {name: "유통권", key: "market"},
    RESIDENTIAL: {name: "주거권", key: "household"}
};

Object.freeze(AreaType);

const PropertyItem = forwardRef(
    ({property, showModal, setProperties}, ref) => {
        const [isGroupSpread, setIsGroupSpread] = useState(false);
        const [getUploadUrl] = useLazyGetUploadUrlQuery();
        const [uploadFile] = useUploadFileMutation();
        const [printImage] = usePrintImageMutation();
        const [getDetail] = useLazyGetDetailQuery();

        if (!property) return null;
        const hasGroup = property.rooms?.length > 0;
        const modalPath = "/property/" + property.id;
        const selectedProperty = property;
        const handleClick = ({modalPath, selectedProperty}) => {
            //그룹이 아닌 경우
            if (!selectedProperty?.group_id)
                showModal({modalPath, selectedProperty});
            //그룹인 경우
            else {
                const room = selectedProperty.id;
                modalPath =
                    "/property/" +
                    String(selectedProperty.property_id) +
                    "?r=" +
                    String(room);
                console.log("그룹 클릭 modalPath:", modalPath);
                showModal({modalPath, selectedProperty});
            }
        };

        const handleClickDownload = async (imageUrl) => {
            const id = property.id;
            const detail = await getDetail({id, format:'print'});
            const param = toPrintJson(detail.data.contents);

            var mapImageBytes;
            if (property.longitude && property.latitude) {
                var res = await fetch(
                    `https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=300&h=300&center=${property.longitude},${property.latitude}&level=14&markers=type:d|size:mid|pos:${property.longitude}%20${property.latitude}|viewSizeRatio:0.5&scale=1&X-NCP-APIGW-API-KEY-ID=gswze0woxr&X-NCP-APIGW-API-KEY=9X7I0xCkXc2wvgYSMKhL5ICc0oU2nGzBFzJcmAV7`
                )
                mapImageBytes = await res.blob();
                mapImageBytes = new Uint8Array(await mapImageBytes.arrayBuffer());
            }
            const LimitSize = 1000 * 90;
            if (mapImageBytes) {
                const imgEncoded = btoa(String.fromCharCode(...mapImageBytes));
                if (imgEncoded.length > LimitSize) { // 90kb 이상이면 장고에서 못받는다.
                    const {data} = await getUploadUrl({fileName: `${property.id}/map`, contentType: 'image/jpeg'});
                    if (!data.success) {
                        throw new Error('Failed to get upload url');
                    }
                    const {uploadUrl, url} = data.contents;
                    const response = await uploadFile({uploadUrl, url, file: mapImageBytes});
                    if (!response?.data?.url) {
                        throw new Error('Failed to upload image');
                    }
                    param['mapImgUrl'] = response.data.url;
                } else {
                    param['mapImg'] = imgEncoded;
                }
            }
            if (param['address']) {
                const addressParts = param['address'].toString().split(" ");
                if (addressParts.length > 2) {
                    param['path'] = addressParts.slice(2).join(" ");
                } else {
                    param['path'] = param['address'];
                }
            }
            const response = await printImage({id, param});
            const url = response.data.contents.url;

            const link = document.createElement("a");
            link.href = url;
            link.download = imageUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }


        function getMdNameForDisplay(mdName) {
            const keywordToMd = {
                '치과': ['치과'],
                '미용': ['피부과'],
                '감기': ['내과', '이비인후과', '소아과'],
                '통증': ['정형외과', '마취통증의학과'],
                '한의원': ['한의원'],
            };
            const mdNames = ['치과', '미용', '감기', '통증', '한의원'];
            let result = [];

            for (let e of mdNames) {
                if (mdName.includes(e) && keywordToMd.hasOwnProperty(e)) {
                    result = result.concat(keywordToMd[e]);
                }
            }
            return result;
        }

        function toPrintJson(property) {
            function formatCurrency(value, withComma = true) {
                const billionPart = Math.floor(value / 10000);
                const thousandPart = value % 10000;
                let result = "";

                function formatWithComma(number) {
                    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }

                if (billionPart > 0) {
                    result += `${withComma ? formatWithComma(billionPart) : billionPart}억 `;
                }
                if (thousandPart > 0 || billionPart === 0) {
                    result += `${withComma ? formatWithComma(thousandPart) : thousandPart}만원`;
                }
                return result;
            }

            function getDateConvert(from) {
                if (from === null || from === undefined || from === '') return '';

                try {
                    const formattedStr = `${from.substring(0, 4)}-${from.substring(4, 6)}-${from.substring(6, 8)}`;
                    const date = new Date(formattedStr);

                    if (isNaN(date.getTime())) {
                        return 'Invalid date';  // 유효하지 않은 날짜일 경우 에러 메시지 반환
                    }

                    // Intl.DateTimeFormat을 사용하여 포맷 적용
                    return new Intl.DateTimeFormat('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }).format(date).replace('. ', '년 ').replace('. ', '월 ').replace('.', '일');
                } catch (e) {
                    return from;
                }
            }

            function getThumbnailImgUrl(imageModels) {
                if (!imageModels || imageModels.length === 0) {
                    return null;
                }
                const thumbnails = imageModels.filter(element => element.is_thumbnail);
                const firstImg = imageModels.length > 0 ? imageModels[0].url : null;
                const url = thumbnails.length > 0 ? thumbnails[0].url : firstImg;
                return url;
            }


            const parsed = Date.parse(property.available_date || '') ? new Date(property.available_date) : null;
            const parsedAvailableDate = parsed ? `${parsed.getFullYear()}년 ${(parsed.getMonth() + 1)}월 이후` : property.available_date || '';

            const areaLists = {};

            function addMap(key, value) {
                if (!areaLists[key]) {
                    areaLists[key] = [];
                }
                areaLists[key].push(value);
            }

            const stationMap = {};
            property.station?.forEach(element => {
                if (!element.id) {
                    return;
                }
                var newName = element.name;
                if (!element.name.endsWith("역")) {
                    newName = element.name + "역";
                }
                if (!stationMap[element.name]) {
                    stationMap[element.name] = {
                       ...element,
                        name: newName
                    };
                    addMap(AreaType.STATION.name, stationMap[element.name]);
                }
            });

            property.market?.forEach(element => {
                addMap(AreaType.DISTRIBUTION.name, element);
            });

            function getName(count, address) {
                if (address && (address.startsWith("서울") || address.startsWith("인천"))) {
                    return `반경 500m 내 ${convertDigits(count)}세대`;
                }
                return `반경 1km 내 ${convertDigits(count)}세대`;
            }
            function convertDigits(number) {
                return number.toLocaleString();  // 이를 통해 콤마를 포함한 숫자 포맷을 얻을 수 있습니다.
            }

            if (property.area_type?.includes("주거권")) {
                addMap(AreaType.RESIDENTIAL.name, {
                    count: property.household || 0,
                    name: getName(property.household || 0, property.address)
                });
            }

            const areaMap = {};
            property.area_type?.forEach(element => {
                areaMap[element] = areaLists[element]?.map(e => e.name).join(", ") || '';
            });


            function getRecommendMdNameForDisplay(recommendedMdName, recommendedMdNameFixed) {
                return getMdNameForDisplay(recommendedMdName || recommendedMdNameFixed || []);
            }

            function getAvailableMdNameForDisplay(availableMdName, availableMdNameFixed) {
                return getMdNameForDisplay(availableMdName || availableMdNameFixed || []);
            }


            let data = {
                id: property.id,
                createdAt: property.created_at,
                updatedAt: property.updated_at,
                address: property.address || '',
                buildingName: property.building_name || '',
                floor: property.floor || '',
                buildingSize: `지상 ${property.max_floor || 0}층 지하 ${property.min_floor || 0}층`,
                completionDate: property.completion_date ? getDateConvert(property.completion_date) : '입주시기 참조',
                elevator: `${property.elevator_customer || 0}대`,
                totalParking: `${property.total_parking || 0}대`,
                deposit: property.deposit ? formatCurrency(property.deposit) : '',
                monthlyRent: property.monthly_rent ? formatCurrency(property.monthly_rent) : '',
                maintenanceCost: property.maintenance_cost_str || '',
                rentFloor: `${property.floor} ${property.rent_scale}`,
                exclusiveArea: `${Math.floor(((property.exclusive_area || 0)/3.3))}평`,
                contactArea: `${Math.floor(((property.contact_area || 0)/3.3))}평`,
                majorUse: property.major_use || '',
                availableDate: parsedAvailableDate,
                freeParking: property.free_parking_str || '불가능',
                visitParking: property.visit_parking || '',
                availableMd: getAvailableMdNameForDisplay(property.available_md_name, property.available_md_name_fixed),
                recommendMd: getRecommendMdNameForDisplay(property.recommended_md_name, property.recommended_md_name_fixed),
                area: areaMap,
                imgUrl: getThumbnailImgUrl(property.file?.image_outside),
                defaultImgUrl: getThumbnailImgUrl(property.file?.image_map),
                description: property.description || '',
                hospital: property.hospital?.map(e => e.name) || [],
                pharmacy: property.pharmacy?.map(e => e.name) || [],
                roomId: property.room?.id || '',
                ptHsAvailableFloor: property.extra.pt_hs_available_floor || '',
                bdHsAvailable: property.extra.bd_hs_available || '',
                smMdOpenAvailable: property.extra.sm_md_open_available || '',
                handicapElv: property.extra.handicap_elv,
                handicapParking: property.extra.handicap_parking,
                handicapRamp: property.extra.handicap_ramp,
                handicapWc: property.extra.handicap_wc,
                transferMoney: property.extra.transfer_money
            };

            if (property.extra.key_money_bool) {
                data.keyMoneyBool = property.extra.key_money_bool === true ? '있음' : '없음';
            }

            if (property.room?.room_number) {
                data = {
                    rooms: property.room.room_number,
                    ...data,
                };
            }

            console.log(data);
            return data;
        }

        const handleClickGroup = () => {
            console.log("click group");

            if (!isGroupSpread) {
                setIsGroupSpread(!isGroupSpread);
                const newRooms = property.rooms.map((room, index) => {
                    return {
                        available_md_name: property.available_md_name,
                        file: property.file,
                        address: property.address,
                        building_name: property.building_name,
                        floor: property.floor,
                        ...room,
                        group_id: `${property.id}_${index + 1}`,
                    };
                });
                console.log("그룹 닫혀있어서 열기, newRooms:", newRooms);
                setProperties((prev) => {
                    const newProperties = [];

                    prev.forEach((p) => {
                        newProperties.push(p);

                        if (p.id === property.id) {
                            newProperties.push(...newRooms);
                        }
                    });
                    return newProperties;
                });
            } else {
                console.log("그룹 열려있어서 닫기");
                setIsGroupSpread(!isGroupSpread);
                setProperties((prev) => {
                    const newProperties = [];

                    prev.forEach((p) => {
                        if (!(p.group_id && p.group_id.startsWith(`${property.id}_`))) {
                            newProperties.push(p);
                        }
                    });

                    console.log("열린거 닫기 길이", newProperties.length);
                    return newProperties;
                });
            }
        };
        let content;
        const key_id = property?.group_id ? property.group_id : property.id;
        if (property.available_md_name === null) {
            content = cateArray.map((cate) => {
                return (
                    <span
                        key={`${cate}cate${key_id}`}
                        className="text-sm font-bold mr-3 text-gray-300"
                    >
            {cate}
          </span>
                );
            });
        } else {
            content = cateArray.map((cate) => {
                if (property.available_md_name.includes(cate)) {
                    return (
                        <span
                            key={`${cate}cate${key_id}`}
                            className="text-sm font-bold mr-3"
                        >
              {cate}
            </span>
                    );
                } else
                    return (
                        <span
                            key={`${cate}cate${key_id}`}
                            className="text-sm font-bold mr-3 text-gray-300"
                        >
              {cate}
            </span>
                    );
            });
        }

        // src\Images\defaultImage.png
        const imageUrl = property.file.image_outside[0]?.url || defaultImage;

        return (
            <div ref={ref} className="bg-white p-1 shadow-md rounded pb-2 border-b">
                <img
                    src={imageUrl}
                    alt="Listing"
                    className="w-full h-40 object-cover rounded"
                />
                <div className="mt-1.5 px-4">
                    <div className="flex items-center space-x-5 text-base">
                        <div
                            onClick={() => handleClick({modalPath, selectedProperty})}
                            className="text-blue-600 cursor-pointer"
                        >
                            {property?.group_id ? property.group_id : property.id}
                        </div>

                        <DownloadPropertyButton imageUrl={imageUrl} downloadFunc={handleClickDownload}/>
                        {hasGroup && (
                            <Button onClick={handleClickGroup} className="w-10 h-5">
                                {isGroupSpread ? (
                                    <SlArrowUp className="w-2 h-2"/>
                                ) : (
                                    <SlArrowDown className="w-2 h-2"/>
                                )}
                            </Button>
                        )}
                        {property.group_id &&
                        property.group_id.startsWith(`${property.property_id}_`) ? (
                            <Button className="w-10 h-5">묶음</Button>
                        ) : (
                            ""
                        )}
                        <div className="flex-grow"></div>
                        <div className="text-base text-sm">
                            확보 {property.is_verified ? "O" : "X"}
                        </div>
                    </div>
                    <div className="mt-1">
                        <p className="text-sm">{property.address}</p>
                    </div>

                    <div className="flex flex-col justify-between">
                        {property.building_name}
                    </div>
                    <div className="flex flex-col justify-between">
                        {property.floor}
                    </div>
                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end space-x-4">
              <span className="text-sm">
                <span className="font-bold">전용</span>{" "}
                  {Math.floor(((property.exclusive_area || 0)/3.3))}평
              </span>
                            <span className="text-sm">
                <span className="font-bold">임대</span> {Math.floor(((property.contact_area || 0)/3.3))}
                                평
              </span>
                        </div>
                    </div>
                    <div className="mt-2 flex text-sm">
                        <div className="flex-grow text-left">
                            <span className="text-gray-400 font-bold">보 </span>
                            {Number(property.deposit) > 10000
                                ? `${Number(property.deposit) / 10000}억원`
                                : `${property.deposit === null ? "" : property.deposit} 만원`}
                        </div>
                        <div className="flex-grow text-center ">
                            <span className="text-gray-400 font-bold">임 </span>
                            {Number(property.monthly_rent) > 10000
                                ? `${Number(property.monthly_rent) / 10000}억원`
                                : `${
                                    property.monthly_rent === null ? "" : property.monthly_rent
                                } 만원`}
                        </div>
                        <div className="flex-grow text-right">
                            <span className="text-gray-400 font-bold">관 </span>
                            {property.maintenance_cost_str
                                ? property.maintenance_cost_str
                                : "만원"}
                        </div>
                    </div>
                    <div className="mt-1.5 mb-2">{content}</div>
                </div>
            </div>
        );
    }
);

// export default PropertyItem;
export default React.memo(PropertyItem);
